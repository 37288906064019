import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['inputField']

  addTag(e) {
    this.inputFieldTarget.value += ` ${e.currentTarget.dataset.name}`;
    e.currentTarget.remove()
  }
}
