import { Controller } from "stimulus"
import Chart from 'chart.js';

export default class extends Controller {
  connect() {
    this.initChart()
  }

  initChart() {
    new Chart(this.element, this.chartDetails())
  }

  chartDetails() {
    return {
      type: this.element.dataset.chartType,
      data: this.chartData(),
      options: this.element.dataset.chartOptions ? JSON.parse(this.element.dataset.chartOptions) : null,
    }
  }

  chartData() {
    return {
      labels: JSON.parse(this.element.dataset.chartLabels),
      datasets: JSON.parse(this.element.dataset.chartDatasets),
    }
  }
}
