import { Controller } from "stimulus";
import { navigator } from "@hotwired/turbo"
import Rails from "@rails/ujs";

export default class extends Controller {
  submit() {
    Rails.fire(this.element, 'submit');
  }

  navigate() {
    navigator.submitForm(this.element);
  }
}
